<template>
    <div>
        <div class="header" id="header">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组763_1686894590711.png" class="hwlogo" @click="toindex">
            <div class="headericon" id="headericon" @click="headericon"></div>
        </div>
        <div class="headerhidden" id="headerhidden">
            <div class="headeritem" @click="toindex"> <span> 首页 </span> </div>
            <div class="headeritem" v-for="(item) in columnData" :key="item.id" @click="toLink(item.url)">
                <span>{{item.columnName}}</span>
            </div>
        </div>
        <div style="height: 1.08rem;"></div>

        <iframe src="https://piaofang.maoyan.com/box-office?ver=normal" scrolling="auto" style="width:100%;height:1000px;border: 0;"></iframe>

<!-- 
        
        <div class="jrpfBox">
            <div class="jrpfTopBox">
                <div class="jrpfTopBox1">
                    <span class="jrpfTopBox1Span">全国大盘</span>
                    <el-date-picker
                    v-model="value1"
                    type="date"
                    placeholder="选择日期"
                    @change="changeDate">
                    </el-date-picker>
                    <span class="jrpfTopBox1Span">含服务费</span>

                </div>
                <div class="jrpfTopBox2">
                    <div class="lastDay">
                        <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/路径385_1684994462093.png">
                        <span>前<br/>一<br/>天</span>
                    </div>
                    <div class="jrpfDataBox">
                        <div class="jrpfDataBoxs">
                            <p class="jrpfDataBoxsP1">实时<br/>数据</p>
                            <p class="jrpfDataBoxsP2">5996.7</p>
                            <p class="jrpfDataBoxsP3">万</p>
                        </div>
                        <p style="font-size: .24rem;color: #393939;textAlign:center;">最近更新 14:39</p>
                    </div>
                    <div class="nextDay">
                        <span>后<br/>一<br/>天</span>
                        <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/路径386_1684994705536.png">
                    </div>
                </div>

            </div>

            <div style="height:.12rem;background: #F6F6F6;"></div>
            
            <div class="jrpfListBox">
                <div class="jrpfListBoxTitle">票房排名</div>
                <div class="jrpfListBoxMenu">
                    <p style="width:38%;">影片名称</p>
                    <p style="width:22%;textAlign:right;">票房(万)</p>
                    <p style="width:22%;textAlign:right;">场次</p>
                    <p style="width:calc(20% - 0.13rem);textAlign:right;">上座率</p>
                </div>
                <div class="jrpfList"  v-if="jrpfList">
                    <div class="jrpfItem"  v-for="(item,index) in jrpfList" :key="index" :style="{background: index % 2 ?  '#FBFBFB' : '#FFFFFF'}" >
                        <div class="jrpfItemData1">
                            <p>{{item.name}}</p>
                            <p>{{item.data1}}</p>
                        </div>
                        <div class="jrpfItemData2">{{item.data2}}</div>
                        <div class="jrpfItemData3">{{item.data3}}</div>
                        <div class="jrpfItemData4">{{item.data4}}</div>

                    </div>
                </div>
            </div>
 -->

        <Foot />


        </div>






    </div>
</template>



<script>
import Foot from "@/components/foot.vue"
import Cookies from 'js-cookie'
import {getListColumn} from "@/api/index"
import { Loading ,Message } from 'element-ui';
import store from '@/store'
export default {
    components:{
        Foot
    },
    data () {
        return {
            headerflag : false,
            hiddenflag : false,
            jrpfList:[
                {
                    "name": "速度与激情10",
                    "data1": "上映13天 4.30亿",
                    "data2": "5,224.0",
                    "data3": "169,783",
                    "data4": "6.1%",
                },
                {
                    "name": "速度与激情10",
                    "data1": "上映13天 4.30亿",
                    "data2": "5,224.0",
                    "data3": "169,783",
                    "data4": "6.1%",
                },
                {
                    "name": "速度与激情10",
                    "data1": "上映13天 4.30亿",
                    "data2": "5,224.0",
                    "data3": "169,783",
                    "data4": "6.1%",
                },
                {
                    "name": "速度与激情10",
                    "data1": "上映13天 4.30亿",
                    "data2": "5,224.0",
                    "data3": "169,783",
                    "data4": "6.1%",
                },
                {
                    "name": "速度与激情10",
                    "data1": "上映13天 4.30亿",
                    "data2": "5,224.0",
                    "data3": "169,783",
                    "data4": "6.1%",
                },
                {
                    "name": "速度与激情10",
                    "data1": "上映13天 4.30亿",
                    "data2": "5,224.0",
                    "data3": "169,783",
                    "data4": "6.1%",
                },
            ],
            total:null,
            pageSize:8,
            currPageNo:1,
            articleload:true,
            t : null,
            clientHeight : null,
            scrollHeight : null,
            load : null,
            value1:"",
            columnData:null,
        }
    },
    computed: {
        columnList() {
            return this.$store.getters.columnList
        },
    },
    methods:{
        toLink(name){
            //if(name == "yszb"){
                //window.location.href="http://www.zygart.cn"; 
            //}else{
                this.reset()
                this.$router.push({name:name});
            //}
        },
        toBack(){
            this.reset()
            // if(this.$route.params.linkType && this.$route.params.linkType == 1){
            //     this.$router.push({name:'index'});
            // }else if(this.$route.params.linkType && this.$route.params.linkType == 2){
            //     this.$router.push({name:'movielist'});
            // }else{
            //     this.$router.push({name:'index'});
            // }
            this.$router.back(-1)
        },
        toyszb(){
            this.reset()
            this.$router.push({name:'yszb'});
        },
        tojrpf(){
            this.reset()
            this.$router.push({name:'jrpf'});
        },
        tohzjg(){
            this.reset()
            this.$router.push({name:'hzjg'});
        },
        tobsxw(){
            this.reset()
            this.$router.push({name:'bsxw'});
        },
        tozcfg(){
            this.reset()
            this.$router.push({name:'zcfg'});
        },
        toindex(){
            this.reset()
            this.$router.push({name:'index'});
        },
        toaboutus(){
            this.reset()
            this.$router.push({name:'gywm'});
        },
        tonews(){
            this.reset()
            this.$router.push({name:'hydt'});
        },
        tonews2(){
            this.reset()
            document.body.scrollTop = document.documentElement.scrollTop = 0
            this.$router.push({name:'news'});
        },
        togsdt(){
            this.reset()
            this.$router.push({name:'gsdt'});
        },
        toyszx(){
            this.reset()
            this.$router.push({name:'yszx'});
        },
        tohyfx(){
            this.reset()
            this.$router.push({name:'hyfx'});
        },
        tozcjd(){
            this.reset()
            this.$router.push({name:'zcjd'});
        },
        tomovielist(){
            this.reset()
            this.$router.push({name:'xmpl'});
        },
        tongyhd(){
            this.reset()
            this.$router.push({name:'gyhd'});
        },
        touserguide(){
            this.reset()
            this.$router.push({name:'userGuide'});
        },
        tocu(){
            this.reset()
            this.$router.push({name:'connectus'});
        },
        headericon(){
            var headericon = document.getElementById("headericon")
            var headerhidden = document.getElementById("headerhidden")
            if(this.headerflag){
                headerhidden.style.top = "-100%"
                headericon.classList.remove("headericon2")
                headericon.classList.add("headericon")
                this.headerflag = false
            }else{
                headerhidden.style.top = "1.06rem"
                headericon.classList.remove("headericon")
                headericon.classList.add("headericon2")
                this.headerflag = true
            }
        },
        headjt(){
            var headjt = document.getElementById("headjt")
            var hiddenbox = document.getElementById("hiddenbox")
            if(this.hiddenflag){
                hiddenbox.style.height = "0"
                headjt.style.transform = "rotateZ(0deg)"
                this.hiddenflag = false
            }else{
                hiddenbox.style.height = "4rem"
                headjt.style.transform = "rotateZ(-180deg)"
                this.hiddenflag = true
            }
        },
        reset(){
        this.headerflag = false
        this.hiddenflag = false
        var headericon = document.getElementById("headericon")
        var headerhidden = document.getElementById("headerhidden")
        //var headjt = document.getElementById("headjt")
        //var hiddenbox = document.getElementById("hiddenbox")
        headerhidden.style.top = "-100%"
        headericon.classList.remove("headericon2")
        headericon.classList.add("headericon")
        //hiddenbox.style.height = "0"
        //headjt.style.transform = "rotateZ(0deg)"
    },
        windowscroll(){
            this.t = document.documentElement.scrollTop || document.body.scrollTop;
            this.clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
            this.scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
            var url = this.$route.path


        },
        showLoading(){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        changeDate(){
            console.log(this.value1)
        }
    },
    mounted(){
        
        this.showLoading()

        // let head = document.getElementsByTagName('head');
        // let meta = document.createElement('meta');
        // document.querySelector('meta[name="keywords"]').setAttribute('content', "新闻资讯-影视资讯-湖北引未来影视")
        // document.querySelector('meta[name="description"]').setAttribute('content', "新闻资讯-影视资讯-湖北引未来影视")
        // meta.content = {
        //     keywords:"新闻资讯-影视资讯-湖北引未来影视",
        //     description:"新闻资讯-影视资讯-湖北引未来影视",
        // };
        // head[0].appendChild(meta)
        // document.title = "新闻资讯-影视资讯-湖北引未来影视";


        this.t = document.documentElement.scrollTop || document.body.scrollTop;
        this.clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
        this.scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        window.addEventListener('scroll',this.windowscroll)

        console.log(new Date())
        this.value1 = new Date();


        $("iframe").attr("src","https://piaofang.maoyan.com/box-office?ver=normal")

        if(this.columnList){
            console.log("store" + this.columnList)
            this.columnData = this.columnList;
            this.load && this.load.close();
        }else{
            getListColumn().then(res => {
                //console.log(res)
                this.columnData = res.result;

                store.dispatch('SetColumnList', res.result)

                this.load && this.load.close();


            })
        }

        

        //console.log("今日票房")

        //location.reload();


    },
    beforeDestroy (){
        window.removeEventListener('scroll',this.windowscroll)
    },
    filters:{
        dataFilter:function(val){
            if (!val) return ''
            return val.substring(0,10)
        }
    },
}
</script>




<style lang="scss">

.jrpfBox{
    width: 100%;
    box-sizing: border-box;
    background: #F6F6F6;
    padding-top: .02rem;
    .jrpfTopBox{
        background-color: #ffffff;
        padding-bottom: .12rem;
        .jrpfTopBox1{
            display: flex;
            padding: 0.3rem 0.36rem;
            box-sizing: border-box;
            justify-content: space-between;
            align-items: center;
            .jrpfTopBox1Span{
                font-size: .24rem;
                font-weight: 400;
                color: #393939;
                line-height: .28rem;
            }
            .el-date-editor{
                width: auto;
                input{
                    border: 0 !important;
                    padding: 0 !important;
                    text-align: center;
                    font-size: .24rem;
                    line-height: .28rem;
                    height: .28rem;
                    font-weight: 600;
                    color: #393939;
                }
            }
            .el-input__prefix{
                display: none !important;
            }
            .el-input__suffix{
                display: none !important;
            }
        }
        .jrpfTopBox2{
            display: flex;
            box-sizing: border-box;
            justify-content: space-between;
            align-items: center;
            padding-bottom: .4rem;
            .lastDay{
                width: .84rem;
                height: 1.2rem;
                background: #F9F9F9;
                border-radius: 0px .14rem .14rem 0px;
                border: 1px solid #F1F1F1;
                display: flex;
                align-items: center;
                img{
                    width: .06rem;
                    height: .14rem;
                    display: block;
                    margin-left: .12rem;
                    margin-right: .2rem;
                }
                span{
                    font-size: .24rem;
                    font-weight: 400;
                    color: #393939;
                    line-height: .24rem;
                }
            }
            .nextDay{
                width: .84rem;
                height: 1.2rem;
                background: #F9F9F9;
                border-radius:  .14rem 0px 0px .14rem;
                border: 1px solid #F1F1F1;
                display: flex;
                align-items: center;
                img{
                    width: .06rem;
                    height: .14rem;
                    display: block;
                    margin-left: .2rem;
                }
                span{
                    font-size: .24rem;
                    font-weight: 400;
                    color: #393939;
                    line-height: .24rem;
                    margin-left: .2rem;

                }
            }
            .jrpfDataBox{
                .jrpfDataBoxs{
                    display: flex;
                    overflow: hidden;
                    .jrpfDataBoxsP1{
                        font-size: .24rem;
                        color: #393939;
                        margin-top: .3rem;
                        margin-right: .16rem;
                    }
                    .jrpfDataBoxsP2{
                        font-size: .88rem;
                        font-weight: 400;
                        color: #AE1F20;
                        line-height: 1.2rem;
                        font-weight: bolder;
                    }
                    .jrpfDataBoxsP3{
                        font-size: .3rem;
                        color: #393939;
                        margin-top: .6rem;

                    }
                }
            }
        }
    }
    
    .jrpfListBox{
        background: #FFFFFF;
        .jrpfListBoxTitle{
            padding: .26rem;
            font-size: .32rem;
            font-weight: bold;
            color: #343434;
        }
        .jrpfListBoxMenu{
            padding: .26rem;
            display: flex;
            background: #FBFBFB;
            p{
                font-size: .28rem;
                font-weight: bold;
                color: #343434;
                white-space: nowrap;

            }
        }
        .jrpfList{
            .jrpfItem{
                padding: .26rem;
                display: flex;
                background: #FFFFFF;
                align-items: center;
                .jrpfItemData1{
                    width:38%;
                    :first-child{
                        font-size: .26rem;
                        font-weight: bold;
                        color: #343434;
                        white-space: nowrap;
                    }
                    :last-child{
                        font-size: .24rem;
                        font-weight: 400;
                        color: #AE1F20;
                        white-space: nowrap;
                    }
                }
                .jrpfItemData2{
                    font-size: .26rem;
                    font-weight: bold;
                    color: #AE1F20;
                    width:22%;
                    text-align:right;
                    white-space: nowrap;
                }
                .jrpfItemData3{
                    font-size: .26rem;
                    font-weight: bold;
                    color: #343434;
                    width:22%;
                    text-align:right;
                    white-space: nowrap;
                }
                .jrpfItemData4{
                    font-size: .26rem;
                    font-weight: bold;
                    color: #343434;
                    width:calc(20% - 0.13rem);
                    text-align:right;
                    white-space: nowrap;
                }
            }
        }
    }
}

iframe::-webkit-scrollbar {
    display: none;
}

</style>